.news {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  &-header {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .date {
      font-size: 14px;
      color: color(primary, base);
      padding-left: 10px;
    }
    .title {
      margin: 0;
      color: color(primary-text);
    }
  }
}

.news-body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  .content {
    color: color(body-text);
    line-height: 200%;
  }
}

.news-card {
  border-radius: 16px;
  outline: 1px solid rgba(57, 57, 57, 0.3);
  padding: 0;
  display: flex;
  flex-direction: column;
  transition: all 200ms;
  height: 100%;
  @include responsive-for(sm) {
    height: auto;
    margin: auto;
    margin-bottom: 2rem;
    width: min(300px, 100%);
  }

  &:hover {
    box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.08);
  }

  &-image {
    img {
      height: 260px;
      object-fit: cover;
      width: 100%;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      border: none;
    }
  }
  &-body {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    &-header {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .date {
        font-size: 14px;
        color: color(primary, base);
      }
      .title {
        font-size: 20px;
        font-weight: 600;
        color: color(primary-text);
      }
    }

    &-content {
      text-align: left;
      color: color(body-text);
      margin: 0;
      padding: 0;
    }

    &-link {
      color: color(primary, base);
      display: flex;
      gap: 10px;
      align-items: center;
      font-weight: 500;
      text-decoration: none;
      margin-top: auto;
    }
  }
}

.news-latest {
  background: color(background-light);
  border-radius: 8px;
  padding: 1rem;
  margin-left: 2rem;

  @include responsive-for(sm) {
    margin-left: 0;
  }

  ul {
    list-style: none;
    margin: 0;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0;

    li {
      a {
        color: color(primary-text);
        display: flex;
        flex-direction: row;
        gap: 10px;

        svg {
          transition: all 200ms;
          margin-right: 10px;
        }

        &:hover {
          svg {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

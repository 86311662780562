.page-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &-main {
        width: min(100%, 1920px);
        margin: 0 auto;
        overflow: hidden;
    }
}

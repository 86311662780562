.branches {
    padding: 3rem 0;

    &-header {
        margin-bottom: 2rem;
        span {
            color: color(body-text);
        }
    }
}

.branch-card {
    cursor: default;
    display: flex;
    flex-direction: column;
    outline: 1px solid rgba(57, 57, 57, 0.3);
    padding: 0;
    border-radius: 4px;
    transition: all 200ms;
    height: 100%;

    @include responsive-for(sm) {
        height: auto;
    }

    &:hover {
        box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.08);
    }

    &-header {
        background: color(primary, base);
        padding: 0;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        padding: 1rem;
        span {
            color: white;
            font-size: 18px;
            font-weight: 500;
        }
    }

    &-body {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .entry {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            word-break: break-all;
        }
    }
}

.branch {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid rgba(57, 57, 57, 0.3);
    border-radius: 4px;
    // padding: 1rem;

    &-header {
        background: color(primary, base);
        padding: 0;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;
        padding: 1rem;
        h2 {
            color: #fff;
            margin: 0;
            padding: 0;
        }
    }

    .entry-container {
        padding: 0 1rem;
        display: flex;
        flex-direction: row;
        gap: 0.25rem;
        justify-content: space-between;
        flex-wrap: wrap;

        .entry {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            word-break: break-all;
        }
    }

    &-body {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        padding: 0 1rem 2rem 1rem;

        &-content {
            &-about {
                &-body {
                    padding: 0 1rem;

                    p {
                        text-align: justify;
                    }
                }
            }
            &-image {
                max-width: 350px;
                img {
                    width: 100%;
                    border-radius: 4px;
                }
            }
        }
        &-team {
            display: flex;
            flex-direction: column;
            gap: 1rem;
        }
        &-gallery {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            .image-container {
                height: 17rem;

                width: 100%;
                object-fit: cover;

                @include responsive-for(sm) {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

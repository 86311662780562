/* 3.0 Heading Size*/
@keyframes preloadAnim {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
* {
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
}

svg:not(:root) {
  overflow: unset;
}

html {
  text-rendering: optimizeLegibility;
  overflow-y: scroll;
}

div {
  color: #000000;
}

body {
  margin: 0;
  font-weight: 400;
  background: #ffffff;
  color: #000000;
}

h1 {
  font-size: 3.75rem;
  line-height: 68px;
  font-weight: 700 !important;
}
@media all and (max-width: 768px) {
  h1 {
    font-size: 2.75rem;
    line-height: 3rem;
  }
}

h2 {
  font-size: 2.5rem;
  font-weight: 600 !important;
  color: #000000;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.2rem;
}

button:focus,
textarea:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

a {
  text-decoration: none !important;
  cursor: pointer;
}
a:hover {
  color: black;
}

thead {
  color: white;
  border-style: hidden !important;
}

th {
  font-weight: 500;
  font-size: 20;
  padding-top: 0.7rem !important;
  padding-bottom: 0.7rem !important;
}

td {
  border: none !important;
  padding: 0.7rem !important;
}

.row {
  row-gap: 1.5rem;
}
@media all and (max-width: 576px) {
  .row {
    row-gap: 0;
  }
}

p {
  line-height: 28px;
  text-align: justify;
}

.my-navbar {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 20;
  background: linear-gradient(180deg, #1d7c5b 0%, #2a7b5f 100%);
  display: block;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  outline-offset: 2px;
  border-bottom: 0.2rem solid #d68b5b;
}
.my-navbar-main {
  padding: 0.5rem 0 0.4rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 1024px) {
  .my-navbar-main {
    display: none;
  }
}
.my-navbar-main .logo {
  flex: 1 1 0%;
  min-height: 85px;
  display: flex;
  align-items: center;
  justify-items: center;
}
.my-navbar-main .logo-image {
  min-width: 400px;
  max-width: 100%;
}
.my-navbar-main .nav {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.my-navbar-main .nav-links {
  color: #fff;
  position: relative;
  width: 100%;
  padding: 0rem 0.25rem;
  font-size: 15px;
}
.my-navbar-main .nav-links::after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 0%;
  left: 0%;
  top: 100%;
  height: 2px;
  margin-top: 4px;
}
.my-navbar-main .nav-links:hover::after {
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0%;
  top: 100%;
  height: 2px;
  transition: linear;
  transition-duration: 200ms;
}
.my-navbar-main .nav-dropdown {
  padding: 0;
  margin: 0;
}
.my-navbar-main .nav-dropdown-header {
  cursor: pointer;
  display: flex;
  gap: 6px;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: #fff;
}
.my-navbar-main .nav-dropdown .menu {
  position: absolute;
  display: none;
  margin: 0;
  z-index: 1000;
  padding: 1rem 0;
  list-style: none;
  background: linear-gradient(180deg, #1d7c5b 0%, #2a7b5f 100%);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.25rem;
  min-width: 10rem;
}
.my-navbar-main .nav-dropdown .menu .item {
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem;
  clear: both;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.my-navbar-main .nav-dropdown .arrow {
  transform: rotate(0deg);
  transition: transform 500ms;
}
.my-navbar-main .nav-dropdown:hover .arrow {
  transform: rotate(-180deg);
  transition: transform 500ms;
}
.my-navbar-main .nav-dropdown:hover .menu {
  display: block;
}
.my-navbar-main .nav .active {
  position: relative;
}
.my-navbar-main .nav .active::after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 100%;
  left: 0%;
  top: 100%;
  height: 2px;
}
.my-navbar .mobile-nav {
  display: none;
  padding: 1rem 0;
  justify-content: space-between;
  align-items: center;
}
@media all and (max-width: 1024px) {
  .my-navbar .mobile-nav {
    display: flex;
  }
}
.my-navbar .mobile-nav a {
  margin-right: 1rem;
  width: min(350px, 100%);
}
.my-navbar .mobile-nav a img {
  width: 100%;
}
.my-navbar .mobile-nav .show-nav {
  background: transparent;
  outline: none;
  border: none;
  color: #fff;
  font-size: 2rem;
  padding: 0;
  margin: 0;
}

.top-navbar {
  z-index: 20;
  background-color: rgb(33, 120, 88);
}
.top-navbar__main {
  padding: 6px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media all and (max-width: 576px) {
  .top-navbar__main {
    flex-direction: column;
    align-items: flex-end;
  }
}
.top-navbar__main .item {
  color: #ddd76b;
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
}

.mobile-sidebar {
  list-style: none;
  display: none;
  flex-direction: column;
  padding: 0;
}
@media all and (max-width: 1024px) {
  .mobile-sidebar {
    display: flex;
  }
}
.mobile-sidebar .link-container {
  display: flex;
  padding: 0;
  margin: 0.5rem 0;
  flex-direction: column;
  gap: 1.5rem;
}
.mobile-sidebar .link-container a {
  color: #000;
}
.mobile-sidebar-link {
  padding: 16px 20px;
  border-bottom: 1px solid #dee2e6;
  color: #000;
}
.mobile-sidebar-link:hover {
  color: #000;
}
.mobile-sidebar ul {
  list-style: none;
}
.mobile-sidebar .accordion-button:not(.collapsed) {
  color: rgb(33, 120, 88);
  background-color: #eef9f5;
}
.mobile-sidebar .top-border {
  border-top: 1px solid #dee2e6;
}

.nepali-text {
  font-weight: 500;
  font-family: sans-serif;
}

.footer {
  background-color: rgb(0, 2, 0);
  margin-top: auto;
}
.footer-main {
  padding-top: 3rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: row;
  gap: 6rem;
}
@media all and (max-width: 480px) {
  .footer-main {
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
    padding-bottom: 2rem;
  }
}
.footer-main .left-about {
  width: 25%;
  flex: 0 0 auto;
}
@media all and (max-width: 480px) {
  .footer-main .left-about {
    width: 100%;
    align-self: flex-start;
  }
}
.footer-main .right-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4rem;
}
@media all and (max-width: 480px) {
  .footer-main .right-links {
    gap: 2rem;
  }
}
.footer-secondary {
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #fff;
}
@media all and (max-width: 480px) {
  .footer-secondary {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
}
.footer-secondary .copy-info {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}
.footer-secondary .socials {
  padding: 0;
  display: flex;
  gap: 1.5rem;
  justify-content: flex-start;
  align-items: center;
}
.footer-secondary .socials .link {
  color: #fff;
  font-size: 1.5rem;
}
.footer .item-container {
  align-self: center;
}
.footer__item {
  color: #fff;
}
.footer__item img {
  max-width: 95%;
}
.footer__item p {
  margin-top: 2rem;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  text-align: justify;
}
.footer__item h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  letter-spacing: 1px;
  font-weight: 500;
}
.footer__item ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer__item ul li {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 1rem;
}
.footer__item ul li svg {
  font-size: 18px;
}
.footer__item ul li a {
  color: #fff;
  transition: all 200ms;
}
.footer__item ul li a:hover {
  color: #ddd76b;
}
.footer__item span {
  margin-left: 1rem;
}
@media all and (max-width: 1024px) {
  .footer__item {
    margin-bottom: 2rem;
  }
}
@media all and (max-width: 480px) {
  .footer {
    padding: 1rem;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.page-container-main {
  width: min(100%, 1920px);
  margin: 0 auto;
  overflow: hidden;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  margin-bottom: 3rem;
}
.loading-container .loader {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #e2e5e7;
}
.loading-container .loader::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-size: 100px 100%;
  background-repeat: no-repeat;
  animation: shimmer 1s ease infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.breadcumb {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/lumbini.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
@media all and (max-width: 576px) {
  .breadcumb {
    min-height: 120px;
    height: -moz-max-content;
    height: max-content;
    padding: 1rem 0;
  }
}
.breadcumb-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
}
.breadcumb-container-breadcumb {
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.breadcumb-container-breadcumb .home-link {
  text-decoration: none;
  color: #fff;
  font-size: 0.9rem;
}
.breadcumb-container-breadcumb .home-link:hover {
  color: #ddd76b;
}
.breadcumb-container-header h1 {
  font-weight: 700;
  font-size: 2.5rem;
  letter-spacing: 1px;
  color: #fff;
  margin: 0;
  padding: 0;
}
@media all and (max-width: 576px) {
  .breadcumb-container-header h1 {
    font-size: 1.5rem;
    letter-spacing: normal;
  }
}

.gallery {
  margin: 3rem 0;
}
.gallery__header h1 {
  margin: 0;
}
.gallery .image-container {
  height: 17rem;
  background-color: rgb(185, 185, 185);
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 576px) {
  .gallery .image-container {
    margin-bottom: 1rem;
  }
}

.message {
  background: rgb(59, 103, 87);
  padding: 6rem 0;
}
@media all and (max-width: 576px) {
  .message {
    padding: 3rem 0;
  }
}
.message-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
}
@media all and (max-width: 576px) {
  .message-main {
    flex-direction: column-reverse;
    gap: 2rem;
  }
}
.message-main-right {
  display: flex;
  flex-direction: column;
  flex: 1 0 60%;
}
.message-main-right .header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 0.75rem;
}
.message-main-right .header .pill {
  padding: 5px 15px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  width: -moz-max-content;
  width: max-content;
  color: #ddd76b;
  font-family: sans-serif;
}
.message-main-right .header h1 {
  font-size: 42px;
  font-weight: 600;
  color: white;
}
@media all and (max-width: 576px) {
  .message-main-right .header h1 {
    font-size: 42px;
  }
}
.message-main-right div {
  color: #f2f2f2;
}
.message-main-left {
  align-self: flex-start;
  margin-top: 10rem;
  width: 100%;
}
@media all and (max-width: 576px) {
  .message-main-left {
    margin-top: 0;
  }
}
.message-main-left img {
  margin-bottom: 1rem;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  min-height: 400px;
  max-height: 80%;
  border-radius: 4px;
}
.message-main-left .body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 2px;
}
.message-main-left .body span {
  color: #f2f2f2;
  margin: 0;
}
.message-main-left .body .name {
  font-weight: 600;
  margin: 0;
}
.message-main-left .body .designation {
  font-size: 18px;
}

.members {
  padding: 4rem 0;
}
@media all and (max-width: 576px) {
  .members {
    padding: 3rem 0;
  }
}
.members h2 {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 1rem;
}
.members-card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  outline: 1px solid rgba(57, 57, 57, 0.3);
  padding: 0.5rem;
  height: 100%;
}
@media all and (max-width: 576px) {
  .members-card {
    height: auto;
    margin: 1rem auto;
    width: min(300px, 100%);
  }
}
.members-card img {
  width: 100%;
  height: 250px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center bottom;
     object-position: center bottom;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.members-card-information {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
}
.members-card-information-header {
  text-align: center;
}
.members-card-information-header h4 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.members-card-information-header h5 {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.members-card-information-bottom {
  margin-top: auto;
}
.members-card-information-bottom .icon-container {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 16px;
  color: #2e2e2e;
  margin-bottom: 0.5rem;
}
.members-card-information-bottom .icon-container span {
  font-size: 0.9rem;
  word-break: break-all;
  line-height: 1rem;
}

.team-details {
  margin: 3rem 0;
}

.management-card {
  align-items: flex-start;
}
.management-card h4,
.management-card h5 {
  text-align: left;
}

.contact {
  margin: 3rem 0;
}
.contact-form {
  margin-right: 2rem;
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 4px;
}
@media all and (max-width: 1024px) {
  .contact-form {
    margin-right: 0;
  }
}
.contact-form h2 {
  font-size: 2rem;
  line-height: 2rem;
}
.contact-form .header-message {
  padding-bottom: 0.5rem;
}
.contact-form .header-message small {
  color: #2e2e2e;
  font-size: 0.8rem;
}
.contact-form .submit-success {
  margin-top: 2rem;
  background-color: #d1e7dd;
  padding: 1rem;
  border: 1px solid #badbcc;
  border-radius: 4px;
}
.contact-form .submit-success p {
  color: #0f5132;
  margin: 0;
  padding: 0;
}
.contact-form-main {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.contact-form-main-item {
  width: 100%;
}
.contact-form-main-item label {
  font-size: 0.9rem;
  display: flex;
  margin-bottom: 0.3rem;
}
.contact-form-main-item input,
.contact-form-main-item textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(221, 221, 221);
  background: #eef9f5;
  padding: 0.5rem;
  margin-right: 0.5rem;
  font-size: 0.9rem;
}
.contact-form-main-item input::-moz-placeholder, .contact-form-main-item textarea::-moz-placeholder {
  font-size: 0.9rem;
}
.contact-form-main-item input::placeholder,
.contact-form-main-item textarea::placeholder {
  font-size: 0.9rem;
}
.contact-form-main-item--message {
  width: 100%;
}
.contact-form-main-item span {
  display: flex;
  font-size: 0.75rem;
  color: red;
}
.contact-form-main-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
@media all and (max-width: 576px) {
  .contact-form-main-row {
    flex-direction: column;
  }
}
.contact-form-main button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
  border-radius: 4px;
  background-color: rgb(33, 120, 88);
  color: white;
  border: none;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 1px;
  transition: all 200ms;
}
.contact-form-main button:disabled {
  background-color: rgba(33, 120, 88, 0.7);
}
.contact-form-main button:not(:disabled):hover {
  background-color: #026340;
}
.contact-map {
  height: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}
.contact-map iframe {
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 4px;
}
@media all and (max-width: 1024px) {
  .contact-map {
    height: 25rem;
  }
}

.scrolltop {
  background: rgb(33, 120, 88);
  color: white;
  padding: 10px 15px;
  cursor: pointer;
  display: inline;
  position: fixed;
  border: none;
  outline: none;
  border-radius: 50%;
  bottom: 3rem;
  right: 3rem;
  display: inline-block;
  transition: visibility 0.5s ease-in-out;
  box-shadow: 1px 2px 5px rgba(139, 139, 139, 0.637);
  z-index: 99;
}
@media all and (max-width: 576px) {
  .scrolltop {
    right: 1.5rem;
  }
}

.notVisible {
  display: none;
  transition: visibility 0.5s ease-in-out;
}

.carousel-image-item {
  position: relative;
  height: 700px;
  width: 100%;
}
@media all and (max-width: 576px) {
  .carousel-image-item {
    height: 250px;
  }
}
.carousel-image-item img {
  height: 100%;
  width: 100% !important;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}

.home-introduction {
  display: flex;
  flex-direction: row;
  padding: 4rem 0;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}
@media all and (max-width: 576px) {
  .home-introduction {
    flex-direction: column-reverse;
    gap: 0.5rem;
    padding: 1.5rem 0;
  }
}
.home-introduction .left-image {
  flex: 1 1 0%;
  width: 100%;
}
.home-introduction .left-image img {
  width: 100%;
  border-radius: 16px;
  -o-object-fit: fill;
     object-fit: fill;
}
.home-introduction .right-section {
  max-width: 550px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: none;
}
.home-introduction .right-section-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.home-introduction .right-section-header .pill {
  padding: 5px 15px;
  background-color: rgba(54, 181, 134, 0.2);
  border-radius: 40px;
  width: -moz-max-content;
  width: max-content;
  color: #000000;
  font-weight: 500;
}
.home-introduction .right-section-header h2 {
  font-size: 44px;
  font-weight: 600;
}
.home-introduction .right-section-body {
  color: #2e2e2e;
}
.home-introduction .right-section-learn-more {
  color: rgb(33, 120, 88);
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  margin-bottom: 1rem;
}

.home-featured {
  padding: 6rem 0;
  background-color: #eef9f5;
}
.home-featured-main {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}
@media all and (max-width: 768px) {
  .home-featured-main {
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.5rem 0;
  }
}
.home-featured-main .left-section {
  flex: 1 1 0%;
}
.home-featured-main .left-section-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.home-featured-main .left-section-header .pill {
  padding: 5px 15px;
  background-color: rgba(54, 181, 134, 0.2);
  border-radius: 40px;
  width: -moz-max-content;
  width: max-content;
  color: #000000;
  font-weight: 500;
}
.home-featured-main .left-section-header h2 {
  font-size: 44px;
  font-weight: 600;
}
.home-featured-main .left-section-body {
  color: #2e2e2e;
}
.home-featured-main .right-section {
  flex: 1 1 0%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
}
@media all and (max-width: 576px) {
  .home-featured-main .right-section {
    flex-direction: column;
    align-items: center;
  }
}
.home-featured-main .right-section .featured-card {
  width: 50%;
  background: #ffffff;
  padding: 32px 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: all 200ms;
}
@media all and (max-width: 576px) {
  .home-featured-main .right-section .featured-card {
    width: min(300px, 100%);
  }
}
.home-featured-main .right-section .featured-card:hover {
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.08);
}
.home-featured-main .right-section .featured-card-image img {
  width: 90px;
  height: 90px;
  -o-object-fit: cover;
     object-fit: cover;
}
.home-featured-main .right-section .featured-card-header {
  font-size: 20px;
  font-weight: 700;
  color: #000000;
}
.home-featured-main .right-section .featured-card-body {
  color: #2e2e2e;
  line-height: 160%;
  text-align: left;
}
.home-featured-main .right-section .featured-card-link {
  margin-top: auto;
  color: rgb(33, 120, 88);
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
}

.home-news {
  padding: 6rem 0;
  display: flex;
  gap: 4rem;
  flex-direction: column;
}
.home-news .top-section {
  align-self: center;
  text-align: center;
  max-width: 50%;
}
@media all and (max-width: 576px) {
  .home-news .top-section {
    max-width: 100%;
  }
}
.home-news .top-section-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.home-news .top-section-header .pill {
  padding: 5px 15px;
  background-color: rgba(54, 181, 134, 0.2);
  border-radius: 40px;
  width: -moz-max-content;
  width: max-content;
  color: #000000;
  align-self: center;
  font-weight: 500;
}
.home-news .top-section-header h2 {
  font-size: 44px;
  font-weight: 600;
}
.home-news .top-section-body {
  text-align: center;
  color: #2e2e2e;
}

.information {
  padding: 3rem 0;
}
.information-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.information-header .search-box {
  font-size: 20px;
}
.information-header .search-box select {
  height: 35px;
  border-radius: 4px;
  border: 1px solid rgb(33, 120, 88);
  padding-left: 4px;
  outline: none;
  font-size: 16px;
  margin-left: 10px;
}

.about-us {
  background-color: rgb(59, 103, 87);
  padding: 6rem 0;
}
@media all and (max-width: 576px) {
  .about-us {
    padding: 3rem 0;
  }
}
.about-us-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
.about-us-main-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.about-us-main-header .pill {
  padding: 5px 15px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  width: -moz-max-content;
  width: max-content;
  color: #ddd76b;
  font-family: sans-serif;
  text-align: center;
  align-self: center;
}
.about-us-main-header h1 {
  font-size: 52px;
  font-weight: 600;
  color: white;
}
.about-us-main-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  align-self: center;
  justify-content: center;
  max-width: 90%;
}
@media all and (max-width: 576px) {
  .about-us-main-body {
    gap: 2rem;
  }
}
@media all and (max-width: 576px) {
  .about-us-main-body {
    max-width: 100%;
  }
}
.about-us-main-body .content {
  align-self: center;
}
.about-us-main-body .content div {
  color: #f2f2f2 !important;
  margin: 0;
}
.about-us-main-body img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center top;
     object-position: center top;
  height: 500px;
  border-radius: 4px;
}
@media all and (max-width: 576px) {
  .about-us-main-body img {
    height: 200px;
  }
}

.mission-vision-goal {
  display: flex;
  padding: 6rem 0;
  flex-direction: column;
  gap: 5rem;
  list-style: none;
  margin: 0;
}
@media all and (max-width: 576px) {
  .mission-vision-goal {
    gap: 3rem;
    padding: 1rem 0 3rem 0;
  }
}
.mission-vision-goal-item {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}
@media all and (max-width: 576px) {
  .mission-vision-goal-item {
    flex-direction: column-reverse;
    gap: 1rem;
  }
}
.mission-vision-goal-item .content {
  flex: 0 0 65%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.mission-vision-goal-item .content div {
  color: #2e2e2e;
}
@media all and (max-width: 576px) {
  .mission-vision-goal-item .content {
    flex: 1 1 0%;
    gap: 1rem;
  }
}
.mission-vision-goal-item .image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mission-vision-goal-item .image img {
  max-width: 100%;
  height: 256px;
}
@media all and (max-width: 576px) {
  .mission-vision-goal-item .image img {
    height: 100px;
  }
}
.mission-vision-goal-item:nth-child(odd) {
  flex-direction: row-reverse;
}
@media all and (max-width: 576px) {
  .mission-vision-goal-item:nth-child(odd) {
    flex-direction: column-reverse;
  }
}

.news {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.news-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.news-header .date {
  font-size: 14px;
  color: rgb(33, 120, 88);
  padding-left: 10px;
}
.news-header .title {
  margin: 0;
  color: #000000;
}

.news-body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.news-body .content {
  color: #2e2e2e;
  line-height: 200%;
}

.news-card {
  border-radius: 16px;
  outline: 1px solid rgba(57, 57, 57, 0.3);
  padding: 0;
  display: flex;
  flex-direction: column;
  transition: all 200ms;
  height: 100%;
}
@media all and (max-width: 576px) {
  .news-card {
    height: auto;
    margin: auto;
    margin-bottom: 2rem;
    width: min(300px, 100%);
  }
}
.news-card:hover {
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.08);
}
.news-card-image img {
  height: 260px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: none;
}
.news-card-body {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
}
.news-card-body-header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.news-card-body-header .date {
  font-size: 14px;
  color: rgb(33, 120, 88);
}
.news-card-body-header .title {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}
.news-card-body-content {
  text-align: left;
  color: #2e2e2e;
  margin: 0;
  padding: 0;
}
.news-card-body-link {
  color: rgb(33, 120, 88);
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 500;
  text-decoration: none;
  margin-top: auto;
}

.news-latest {
  background: #eef9f5;
  border-radius: 8px;
  padding: 1rem;
  margin-left: 2rem;
}
@media all and (max-width: 576px) {
  .news-latest {
    margin-left: 0;
  }
}
.news-latest ul {
  list-style: none;
  margin: 0;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
}
.news-latest ul li a {
  color: #000000;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.news-latest ul li a svg {
  transition: all 200ms;
  margin-right: 10px;
}
.news-latest ul li a:hover svg {
  margin-left: 5px;
}

.product-header {
  padding: 6rem 0;
  background: rgb(59, 103, 87);
}
@media all and (max-width: 576px) {
  .product-header {
    padding: 4rem 0;
  }
}
.product-header .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding-right: 2rem;
}
@media all and (max-width: 576px) {
  .product-header .left {
    padding-right: 12px;
  }
}
.product-header .left-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.product-header .left-header .pill {
  padding: 5px 15px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  width: -moz-max-content;
  width: max-content;
  color: #ddd76b;
  font-family: sans-serif;
}
.product-header .left-header h1 {
  font-size: 52px;
  font-weight: 600;
  color: white;
}
.product-header .left div {
  color: #f2f2f2;
}
.product-header .left a {
  color: #ddd76b;
}
.product-header .right {
  height: -moz-max-content;
  height: max-content;
  padding-top: 4rem;
}
@media all and (max-width: 576px) {
  .product-header .right {
    padding-top: 2rem;
  }
}
.product-header .right img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.product-body {
  padding: 4rem 0;
}
.product-body .product-children h2 {
  margin-bottom: 1rem;
}
.product-body .product-children-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.product-body .product-children-list-item {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}
.product-body .product-children-list-item-body h4 {
  font-size: 1.25rem;
  font-weight: 500;
}
.product-body .product-children-list-item-body p {
  font-size: 0.9rem;
  color: #2e2e2e;
  text-align: justify;
}
.product-body .product-children-list-item-body img {
  float: right;
  max-width: 150px;
  max-height: 150px;
  margin: 0 0 0 1rem;
}
.product-body .product-children-list-item:nth-child(even) img {
  float: left;
  margin: 0 1rem 0 0;
}
.product-body .other-products {
  padding: 1rem;
  background: #eef9f5;
  border-radius: 8px;
  padding: 1rem;
}
.product-body .other-products span {
  font-weight: 600;
  font-size: 24px;
}
.product-body .other-products-list {
  display: flex;
  margin: 0;
  margin-top: 1rem;
  padding: 0;
  flex-direction: column;
}
.product-body .other-products-list a {
  color: inherit;
  text-decoration: none;
  padding: 0.5rem;
  transition: all 100ms;
  border-bottom: 1px solid rgba(54, 181, 134, 0.2);
}
.product-body .other-products-list a:hover {
  background: rgb(33, 120, 88);
  color: white;
}

.download-card {
  display: flex;
  flex-direction: column;
  outline: 1px solid rgba(57, 57, 57, 0.3);
  padding: 0;
  border-radius: 8px;
  transition: all 200ms;
  height: 100%;
}
@media all and (max-width: 576px) {
  .download-card {
    height: auto;
    width: min(300px, 100%);
    margin: auto;
  }
}
.download-card:hover {
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.08);
}
.download-card:hover .download-button {
  background-color: #026340;
}
.download-card-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.download-card-header .pdf-logo {
  min-height: 128px;
}
.download-card-header .pdf-logo img {
  padding: 1rem;
  width: 128px;
}
.download-card-header .download-button {
  width: 100%;
  color: white;
  background: rgb(33, 120, 88);
  text-align: center;
  padding: 0.5rem 0;
  transition: all 200ms;
}
.download-card-body {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 1rem;
}
.download-card-body .date {
  font-size: 14px;
  color: rgb(33, 120, 88);
}
.download-card-body .title {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.branches {
  padding: 3rem 0;
}
.branches-header {
  margin-bottom: 2rem;
}
.branches-header span {
  color: #2e2e2e;
}

.branch-card {
  cursor: default;
  display: flex;
  flex-direction: column;
  outline: 1px solid rgba(57, 57, 57, 0.3);
  padding: 0;
  border-radius: 4px;
  transition: all 200ms;
  height: 100%;
}
@media all and (max-width: 576px) {
  .branch-card {
    height: auto;
  }
}
.branch-card:hover {
  box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.08);
}
.branch-card-header {
  background: rgb(33, 120, 88);
  padding: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding: 1rem;
}
.branch-card-header span {
  color: white;
  font-size: 18px;
  font-weight: 500;
}
.branch-card-body {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.branch-card-body .entry {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  word-break: break-all;
}

.branch {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid rgba(57, 57, 57, 0.3);
  border-radius: 4px;
}
.branch-header {
  background: rgb(33, 120, 88);
  padding: 0;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding: 1rem;
}
.branch-header h2 {
  color: #fff;
  margin: 0;
  padding: 0;
}
.branch .entry-container {
  padding: 0 1rem;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: space-between;
  flex-wrap: wrap;
}
.branch .entry-container .entry {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  word-break: break-all;
}
.branch-body {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 1rem 2rem 1rem;
}
.branch-body-content-about-body {
  padding: 0 1rem;
}
.branch-body-content-about-body p {
  text-align: justify;
}
.branch-body-content-image {
  max-width: 350px;
}
.branch-body-content-image img {
  width: 100%;
  border-radius: 4px;
}
.branch-body-team {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.branch-body-gallery {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.branch-body-gallery .image-container {
  height: 17rem;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media all and (max-width: 576px) {
  .branch-body-gallery .image-container {
    margin-bottom: 1rem;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: scroll;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100vw;
}
.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}
.popup-container-item {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 1rem;
  background-color: white;
  border: 1px solid rgba(57, 57, 57, 0.3);
  border-radius: 10px;
}
@media all and (max-width: 576px) {
  .popup-container-item {
    gap: 2rem;
    padding: 0.5rem;
  }
}
.popup-container-item-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.popup-container-item-header h3 {
  margin: 0;
  padding: 0;
}
.popup-container-item-header button {
  background: transparent;
  outline: none;
  border: none;
  font-size: 20px;
}
.popup-container-item-image {
  text-align: center;
  min-height: 70vh;
}
.popup-container-item-image .carousel-control-next,
.popup-container-item-image .carousel-control-prev {
  display: block;
  opacity: 1;
  padding-top: 20px;
}
@media all and (max-width: 576px) {
  .popup-container-item-image .carousel-control-next,
  .popup-container-item-image .carousel-control-prev {
    padding-top: 0;
    opacity: 0.4;
  }
}
@media all and (max-width: 576px) {
  .popup-container-item-image {
    min-height: 40vh;
  }
}
.popup-container-item-image span {
  font-size: 20px;
}
.popup-container-item-image img {
  width: 768px;
}
@media all and (max-width: 576px) {
  .popup-container-item-image img {
    width: 80vw;
  }
}/*# sourceMappingURL=App.css.map */
.about-us {
    // background-image: linear-gradient(to bottom,  75%, color(background) 25%);
    background-color: color(about-bg);
    padding: 6rem 0;

    @include responsive-for(sm) {
        // background-image: linear-gradient(to bottom, color(about-bg) 88%, color(background) 12%);

        padding: 3rem 0;
    }

    &-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        &-header {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .pill {
                padding: 5px 15px;
                background-color: rgba(255, 255, 255, 0.2);
                border-radius: 40px;
                width: max-content;
                color: color(top-nav);
                // font-weight: 500;
                font-family: sans-serif;
                text-align: center;
                align-self: center;
            }

            h1 {
                font-size: 52px;
                font-weight: 600;
                color: white;
            }
        }
        &-body {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 3rem;
            align-self: center;
            justify-content: center;

            @include responsive-for(sm) {
                gap: 2rem;
            }
            max-width: 90%;
            @include responsive-for(sm) {
                max-width: 100%;
            }
            .content {
                align-self: center;
                div {
                    // text-align: center;
                    color: #f2f2f2 !important;
                    margin: 0;
                }
            }
            img {
                width: 100%;
                object-fit: cover;
                object-position: center top;
                height: 500px;
                @include responsive-for(sm) {
                    height: 200px;
                }
                border-radius: 4px;
            }
        }
    }
}

.mission-vision-goal {
    display: flex;
    padding: 6rem 0;
    flex-direction: column;
    gap: 5rem;
    list-style: none;
    margin: 0;

    @include responsive-for(sm) {
        gap: 3rem;
        padding: 1rem 0 3rem 0;
    }

    &-item {
        display: flex;
        flex-direction: row;
        gap: 3rem;

        @include responsive-for(sm) {
            flex-direction: column-reverse;
            gap: 1rem;
        }

        .content {
            flex: 0 0 65%;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            div {
                color: color(body-text);
            }

            @include responsive-for(sm) {
                flex: 1 1 0%;
                gap: 1rem;
            }
        }

        .image {
            // flex: 1 1 0%;
            display: flex;
            justify-content: center;
            align-items: center;

            // @include responsive-for(sm) {
            //     justify-content: flex-start;
            //     align-items: flex-start;
            // }

            img {
                max-width: 100%;
                height: 256px;

                @include responsive-for(sm) {
                    height: 100px;
                }
            }
        }
    }

    &-item:nth-child(odd) {
        flex-direction: row-reverse;

        @include responsive-for(sm) {
            flex-direction: column-reverse;
        }
    }
}

.breadcumb {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(../assets/lumbini.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    // filter: brightness(80%);

    @include responsive-for(sm) {
        min-height: 120px;
        height: max-content;
        padding: 1rem 0;
    }

    &-container {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        align-items: center;
        &-breadcumb {
            color: #fff;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            .home-link {
                text-decoration: none;
                color: #fff;
                font-size: 0.9rem;

                &:hover {
                    color: color(top-nav);
                }
            }
        }

        &-header {
            h1 {
                font-weight: 700;
                font-size: 2.5rem;
                letter-spacing: 1px;
                color: #fff;
                margin: 0;
                padding: 0;

                @include responsive-for(sm) {
                    font-size: 1.5rem;
                    letter-spacing: normal;
                }
            }
        }
    }
}

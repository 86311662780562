.my-navbar {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 20;
  background: linear-gradient(180deg, #1d7c5b 0%, #2a7b5f 100%);
  display: block;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  outline-offset: 2px;
  border-bottom: 0.2rem solid #d68b5b;

  &-main {
    padding: 0.5rem 0 0.4rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @include responsive-for(lg) {
      display: none;
    }

    .logo {
      flex: 1 1 0%;
      min-height: 85px;
      display: flex;
      align-items: center;
      justify-items: center;

      &-image {
        min-width: 400px;
        max-width: 100%;
      }
    }

    .nav {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      gap: 1rem;
      // width: 100%;
      // flex-wrap: wrap;
      // gap: 1rem;

      &-links {
        color: #fff;
        position: relative;
        width: 100%;
        padding: 0rem 0.25rem;
        font-size: 15px;

        &::after {
          content: "";
          position: absolute;
          background-color: #fff;
          width: 0%;
          left: 0%;
          top: 100%;
          height: 2px;
          margin-top: 4px;
        }

        &:hover {
          &::after {
            position: absolute;
            background-color: #fff;
            width: 100%;
            left: 0%;
            top: 100%;
            height: 2px;
            transition: linear;
            transition-duration: 200ms;
          }
        }
      }

      &-dropdown {
        padding: 0;
        margin: 0;
        &-header {
          cursor: pointer;
          display: flex;
          gap: 6px;
          flex-direction: row;
          align-items: center;
          font-size: 14px;
          color: #fff;
        }
        .menu {
          position: absolute;
          display: none;
          margin: 0;
          z-index: 1000;
          padding: 1rem 0;
          list-style: none;
          background: linear-gradient(180deg, #1d7c5b 0%, #2a7b5f 100%);

          border: 1px solid rgba(255, 255, 255, 0.15);
          border-radius: 0.25rem;
          min-width: 10rem;

          .item {
            display: block;
            width: 100%;
            padding: 0.25rem 0.5rem;
            clear: both;
            white-space: nowrap;
            background-color: transparent;
            border: 0;
          }
        }
        .arrow {
          transform: rotate(0deg);
          transition: transform 500ms;
        }
        &:hover {
          .arrow {
            transform: rotate(-180deg);
            transition: transform 500ms;
          }
          .menu {
            display: block;
          }
        }
      }

      .active {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          background-color: #fff;
          width: 100%;
          left: 0%;
          top: 100%;
          height: 2px;
        }
      }
    }
  }

  .mobile-nav {
    display: none;
    @include responsive-for(lg) {
      display: flex;
    }
    padding: 1rem 0;
    justify-content: space-between;
    align-items: center;

    a {
      margin-right: 1rem;
      width: min(350px, 100%);
      img {
        width: 100%;
        // min-width: 350px;

        // max-width: 100%;
      }
    }

    .show-nav {
      background: transparent;
      outline: none;
      border: none;
      color: #fff;
      font-size: 2rem;
      padding: 0;
      margin: 0;
    }
  }
}

.top-navbar {
  z-index: 20;
  background-color: color(primary, base);
  &__main {
    padding: 6px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @include responsive-for(sm) {
      flex-direction: column;
      align-items: flex-end;
    }

    .item {
      color: color(top-nav);
      display: flex;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      font-size: 16px;
      font-weight: 300;
    }
  }
}

.mobile-sidebar {
  list-style: none;
  display: none;
  @include responsive-for(lg) {
    display: flex;
  }
  flex-direction: column;
  padding: 0;
  .link-container {
    display: flex;
    padding: 0;
    margin: 0.5rem 0;
    flex-direction: column;
    gap: 1.5rem;
    a {
      color: #000;
    }
  }
  &-link {
    padding: 16px 20px;
    border-bottom: 1px solid #dee2e6;
    color: #000;
    &:hover {
      color: #000;
    }
  }

  ul {
    list-style: none;
  }

  .accordion-button:not(.collapsed) {
    color: color(primary, base);
    background-color: color(background-light);
  }

  .top-border {
    border-top: 1px solid #dee2e6;
  }
}

.nepali-text {
  font-weight: 500;
  font-family: sans-serif;
}

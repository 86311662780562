.download-card {
    display: flex;
    flex-direction: column;
    outline: 1px solid rgba(57, 57, 57, 0.3);
    padding: 0;
    border-radius: 8px;
    transition: all 200ms;
    height: 100%;

    @include responsive-for(sm) {
        height: auto;
        width: min(300px, 100%);
        margin: auto;
    }

    &:hover {
        box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.08);
        .download-button {
            background-color: color(primary, hover);
        }
    }

    &-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .pdf-logo {
            min-height: 128px;

            img {
                padding: 1rem;
                width: 128px;
            }
        }
        .download-button {
            width: 100%;
            color: white;
            background: color(primary, base);
            text-align: center;
            padding: 0.5rem 0;
            transition: all 200ms;
        }
    }
    &-body {
        display: flex;
        flex-direction: column;
        gap: 4px;
        padding: 1rem;

        .date {
            font-size: 14px;
            color: color(primary, base);
        }
        .title {
            font-size: 20px;
            font-weight: 500;
            color: color(primary-text);
        }
    }
}

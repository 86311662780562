/* 3.0 Heading Size*/
$h1: 3.75rem !default;
$h2: 2.5rem !default;
$h3: 1.5rem !default;
$h4: 1.2rem;

// color variable map
$colors: (
  // non-nested values
  primary-text: #000000,
  body-text: #2e2e2e,
  top-nav: #ddd76b,
  background: #ffffff,
  background-light: #eef9f5,
  footer-bg: rgba(0, 2, 0, 1),
  about-bg: rgba(59, 103, 87, 1),
  primary: (
    base: rgba(33, 120, 88, 1),
    pill: rgba(54, 181, 134, 0.2),
    hover: #026340,
    disabled: rgba(33, 120, 88, 0.7),
  )
);

// retrieve color from $colors map ie. `color(base, primary)`
@function color($color-name, $color-variant: null) {
  // color variant is optional
  @if ($color-variant != null) {
    // map inception
    @return map-get(map-get($colors, $color-name), $color-variant);
  } @else {
    @return map-get($colors, $color-name);
  }
}

$primary: color(primary, base);

.product {
    &-header {
        padding: 6rem 0;
        background: color(about-bg);

        @include responsive-for(sm) {
            padding: 4rem 0;
        }

        .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 0.5rem;
            padding-right: 2rem;

            @include responsive-for(sm) {
                padding-right: 12px;
            }

            &-header {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .pill {
                    padding: 5px 15px;
                    background-color: rgba(255, 255, 255, 0.2);
                    border-radius: 40px;
                    width: max-content;
                    color: color(top-nav);
                    // font-weight: 500;
                    font-family: sans-serif;
                }

                h1 {
                    font-size: 52px;
                    font-weight: 600;
                    color: white;
                }
            }

            div {
                color: #f2f2f2;
            }

            a {
                color: color(top-nav);
            }
        }

        .right {
            // align-self: center;
            height: max-content;
            padding-top: 4rem;
            @include responsive-for(sm) {
                padding-top: 2rem;
            }
            img {
                width: 100%;
                object-fit: cover;
                border-radius: 10px;
            }
        }
    }

    &-body {
        padding: 4rem 0;

        .product-children {
            h2 {
                margin-bottom: 1rem;
            }
            &-list {
                list-style: none;
                padding: 0;
                margin: 0;

                &-item {
                    padding: 0.5rem 1rem;
                    margin-bottom: 1rem;

                    &-body {
                        h4 {
                            font-size: 1.25rem;
                            font-weight: 500;
                        }
                        p {
                            font-size: 0.9rem;
                            color: color(body-text);
                            text-align: justify;
                        }

                        img {
                            float: right;
                            max-width: 150px;
                            max-height: 150px;
                            margin: 0 0 0 1rem;
                        }
                    }

                    &:nth-child(even) {
                        img {
                            float: left;
                            margin: 0 1rem 0 0;
                        }
                    }
                }
            }
        }

        .other-products {
            padding: 1rem;
            background: color(background-light);
            border-radius: 8px;
            padding: 1rem;
            span {
                font-weight: 600;
                font-size: 24px;
            }

            &-list {
                display: flex;
                margin: 0;
                margin-top: 1rem;
                padding: 0;
                flex-direction: column;

                a {
                    color: inherit;
                    text-decoration: none;
                    padding: 0.5rem;
                    transition: all 100ms;
                    border-bottom: 1px solid color(primary, pill);

                    &:hover {
                        background: color(primary, base);
                        color: white;
                    }
                }
            }
        }
    }
}

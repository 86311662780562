.message {
  background: color(about-bg);
  padding: 6rem 0;

  @include responsive-for(sm) {
    padding: 3rem 0;
  }

  &-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 3rem;

    @include responsive-for(sm) {
      flex-direction: column-reverse;
      gap: 2rem;
    }

    &-right {
      display: flex;
      flex-direction: column;
      flex: 1 0 60%;

      // @include responsive-for(sm) {
      //     flex: 1 0 0%;
      // }

      .header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 0.75rem;

        .pill {
          padding: 5px 15px;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 40px;
          width: max-content;
          color: color(top-nav);
          // font-weight: 500;
          font-family: sans-serif;
        }

        h1 {
          font-size: 42px;
          font-weight: 600;
          color: white;

          @include responsive-for(sm) {
            font-size: 42px;
          }
        }
      }
      div {
        color: #f2f2f2;
      }
    }
    &-left {
      align-self: flex-start;
      margin-top: 10rem;
      width: 100%;

      @include responsive-for(sm) {
        margin-top: 0;
      }
      img {
        margin-bottom: 1rem;
        width: 100%;
        object-fit: cover;
        min-height: 400px;
        max-height: 80%;
        border-radius: 4px;
      }

      .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 2px;
        span {
          color: #f2f2f2;
          margin: 0;
        }

        .name {
          font-weight: 600;
          margin: 0;
        }

        .designation {
          font-size: 18px;
        }
      }
    }
  }
}

.members {
  padding: 4rem 0;

  @include responsive-for(sm) {
    padding: 3rem 0;
  }

  h2 {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  &-card {
    display: flex;
    flex-direction: column;
    // margin: 0 1rem;
    border-radius: 8px;
    outline: 1px solid rgba(57, 57, 57, 0.3);
    padding: 0.5rem;
    height: 100%;

    @include responsive-for(sm) {
      height: auto;
      margin: 1rem auto;
      width: min(300px, 100%);
    }

    img {
      width: 100%;
      height: 250px;
      object-fit: contain;
      object-position: center bottom;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &-information {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.8rem;
      // height: 100%;
      &-header {
        text-align: center;
        h4 {
          font-size: 1.25rem;
          font-weight: 600;
          margin: 0;
          padding: 0;
        }
        h5 {
          font-size: 1rem;
          font-weight: 500;

          margin: 0;
          padding: 0;
        }
      }

      &-bottom {
        margin-top: auto;
        .icon-container {
          display: flex;
          gap: 10px;
          align-items: center;
          font-size: 16px;
          color: color(body-text);
          margin-bottom: 0.5rem;
          span {
            font-size: 0.9rem;
            word-break: break-all;
            line-height: 1rem;
          }
        }
      }
    }
  }
}

.team-details {
  margin: 3rem 0;
}

.management-card {
  align-items: flex-start;
  h4,
  h5 {
    text-align: left;
  }
}

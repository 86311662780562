.contact {
  margin: 3rem 0;
  &-form {
    margin-right: 2rem;
    margin-bottom: 3rem;
    // border: 1px solid color(primary, base);
    padding: 2rem;
    border-radius: 4px;

    @include responsive-for(lg) {
      margin-right: 0;
    }
    h2 {
      font-size: 2rem;
      line-height: 2rem;
      // margin: 0;
    }
    .header-message {
      // border-bottom: 1px solid color(primary, base);
      padding-bottom: 0.5rem;
      small {
        color: color(body-text);
        font-size: 0.8rem;
      }
    }

    .submit-success {
      margin-top: 2rem;
      background-color: #d1e7dd;
      padding: 1rem;
      border: 1px solid #badbcc;
      border-radius: 4px;
      p {
        color: #0f5132;
        margin: 0;
        padding: 0;
      }
    }
    &-main {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &-item {
        width: 100%;
        label {
          font-size: 0.9rem;
          display: flex;
          margin-bottom: 0.3rem;
        }
        input,
        textarea {
          width: 100%;
          border-radius: 4px;
          border: 1px solid rgb(221, 221, 221);
          background: color(background-light);
          padding: 0.5rem;
          margin-right: 0.5rem;
          font-size: 0.9rem;
          &::placeholder {
            font-size: 0.9rem;
          }
          // @include responsive-for(lg) {
          //   width: 15rem;
          // }
        }
        &--message {
          width: 100%;
        }
        span {
          display: flex;
          font-size: 0.75rem;
          color: red;
        }
      }

      &-row {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        @include responsive-for(sm) {
          flex-direction: column;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.6rem;
        border-radius: 4px;
        background-color: color(primary, base);
        color: white;
        border: none;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 1px;
        transition: all 200ms;

        &:disabled {
          background-color: color(primary, disabled);
        }

        &:not(:disabled):hover {
          background-color: color(primary, hover);
        }

        // @include responsive-for(lg) {
        //   width: 15rem;
        // }
      }
    }
  }
  &-map {
    height: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;

    // border: 1px solid color(primary, base);
    // padding: 2rem;
    // border-radius: 4px;

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
      border-radius: 4px;
    }
    @include responsive-for(lg) {
      height: 25rem;
    }
  }
}

.gallery {
  margin: 3rem 0;
  &__header {
    h1 {
      margin: 0;
    }
  }

  .image-container {
    height: 17rem;
    background-color: rgb(185, 185, 185);

    width: 100%;
    object-fit: cover;

    @include responsive-for(sm) {
      margin-bottom: 1rem;
    }
  }
}

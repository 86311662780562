.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    margin-bottom: 3rem;

    .loader {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        background-color: #e2e5e7;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
            background-size: 100px 100%;
            background-repeat: no-repeat;
            animation: shimmer 1s ease infinite;

            content: "";
        }

        @keyframes shimmer {
            100% {
                transform: translateX(100%);
            }
        }
    }
}

.home-news {
    padding: 6rem 0;
    display: flex;
    gap: 4rem;
    flex-direction: column;

    .top-section {
        align-self: center;
        text-align: center;
        max-width: 50%;

        @include responsive-for(sm) {
            max-width: 100%;
        }

        &-header {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .pill {
                padding: 5px 15px;
                background-color: color(primary, pill);
                border-radius: 40px;
                width: max-content;
                color: color(primary-text);
                align-self: center;
                font-weight: 500;
            }

            h2 {
                font-size: 44px;
                font-weight: 600;
            }
        }
        &-body {
            text-align: center;
            color: color(body-text);
        }
    }
}

.information {
    padding: 3rem 0;
    &-header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;

        .search-box {
            font-size: 20px;

            select {
                height: 35px;
                border-radius: 4px;
                border: 1px solid color(primary, base);
                padding-left: 4px;
                outline: none;
                font-size: 16px;
                margin-left: 10px;
            }
        }
    }
}

// CARD styles in _news.scss file

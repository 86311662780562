.carousel-image-item {
  position: relative;
  height: 700px;
  width: 100%;
  @include responsive-for(sm) {
    height: 250px;
  }
  img {
    height: 100%;
    width: 100% !important;
    object-fit: cover;
    object-position: center center;
  }
}

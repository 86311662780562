.footer {
  background-color: color(footer-bg);
  margin-top: auto;

  &-main {
    padding-top: 3rem;
    padding-bottom: 4rem;
    // border-bottom: 1px solid #dee2e6;
    display: flex;
    flex-direction: row;
    gap: 6rem;

    @include responsive-for(xs) {
      flex-direction: column;
      align-items: flex-start;
      gap: 3rem;
      padding-bottom: 2rem;
    }

    .left-about {
      width: 25%;
      flex: 0 0 auto;
      // align-self: center;

      @include responsive-for(xs) {
        width: 100%;
        align-self: flex-start;
      }
    }

    .right-links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 4rem;

      @include responsive-for(xs) {
        gap: 2rem;
      }
    }
  }

  &-secondary {
    padding-top: 1rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #fff;

    @include responsive-for(xs) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }

    .copy-info {
      color: #fff;
      font-size: 12px;
      font-weight: 300;
    }

    .socials {
      padding: 0;
      display: flex;
      gap: 1.5rem;
      justify-content: flex-start;
      align-items: center;

      .link {
        color: #fff;
        font-size: 1.5rem;
      }
    }
  }

  .item-container {
    align-self: center;
    // margin-right: 4rem;
  }

  &__item {
    color: #fff;

    img {
      max-width: 95%;
      // max-height: 10%;
    }

    p {
      margin-top: 2rem;
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      text-align: justify;
      // margin-right: 1rem;
    }

    h4 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 1.5rem;
      letter-spacing: 1px;
      font-weight: 500;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 1rem;
        svg {
          font-size: 18px;
        }
        a {
          color: #fff;
          transition: all 200ms;
          &:hover {
            color: color(top-nav);
          }
        }
      }
    }
    span {
      margin-left: 1rem;
    }

    @include responsive-for(lg) {
      margin-bottom: 2rem;
    }
  }
  @include responsive-for(xs) {
    padding: 1rem;
  }
}

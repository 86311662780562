.home-featured {
  padding: 6rem 0;
  background-color: color(background-light);

  &-main {
    display: flex;
    flex-direction: row;
    @include responsive-for(md) {
      flex-direction: column;
      gap: 0.5rem;
      padding: 1.5rem 0;
    }
    gap: 3rem;

    .left-section {
      flex: 1 1 0%;
      &-header {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .pill {
          padding: 5px 15px;
          background-color: color(primary, pill);
          border-radius: 40px;
          width: max-content;
          color: color(primary-text);
          font-weight: 500;
        }

        h2 {
          font-size: 44px;
          font-weight: 600;
        }
      }
      &-body {
        color: color(body-text);
      }
    }

    .right-section {
      flex: 1 1 0%;
      display: flex;
      flex-direction: row;
      gap: 2rem;
      @include responsive-for(sm) {
        flex-direction: column;
        align-items: center;
      }

      .featured-card {
        width: 50%;
        background: color(background);
        padding: 32px 24px;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        transition: all 200ms;
        @include responsive-for(sm) {
          width: min(300px, 100%);
        }

        &:hover {
          box-shadow: 0px 14px 44px rgba(0, 0, 0, 0.08);
        }

        &-image {
          img {
            width: 90px;
            height: 90px;
            object-fit: cover;
          }
        }

        &-header {
          font-size: 20px;
          font-weight: 700;
          color: color(primary-text);
        }

        &-body {
          color: color(body-text);
          line-height: 160%;
          text-align: left;
        }

        &-link {
          margin-top: auto;
          color: color(primary, base);
          display: flex;
          gap: 10px;
          align-items: center;
          font-weight: 500;
          text-decoration: none;
        }
      }
    }
  }
}
